<template>
  <modal-card :title="title">
    <site-change-snapshot :site-id="siteId" :change-id="changeId" />
  </modal-card>
</template>
<script>
export default {
  name: "SiteChangeSnapshotModal",
  components: {
    "site-change-snapshot": () => import("./_siteChangeSnapshot.vue")
  },
  props: {
    siteId: {
      required: true,
      type: String
    },
    changeId: {
      required: true,
      type: String
    },
    title: {
      required: false,
      type: String,
      default: "State"
    }
  }
};
</script>
